import React, { useState, useEffect } from "react";
import agent from "../assets/icons/agent_po_headshot.png";
import user from "../assets/images/user.png";
import sendBtn from "../assets/icons/sendBtn.svg";
import SearchSuggestion from "./SearchSuggestion";

const Desktop1 = ({ input, setInput }) => {
  const [showQuestions, setShowQuestions] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [data, setData] = useState(null);
  const [typedData, setTypedData] = useState("");

  const questionData = {
    "What is the order assumption of part number 3U3F-10B885-AA in June'24?": [
      {
        sentence:
          "For part number 3U3F-10B885-AA, the order assumption for June 2024 is 6 units. This part is categorized under the Zspares program, belonging to the Zspares customer group.",
        Customer: "• Customer: Zspares",
        VPN: "• VPN: 3U3F-10B885-AA",
        Program: "• Program: Zspares",
        CustomerGroup: "• CustomerGroup: Zspares",
        "1-Jun": "• Jun'24: 6",
      },
    ],
    "What is the assumption of total orders of all parts in May'24?": [
      {
        sentence:
          "The assumption of total orders of all parts in May'24 is 271423. Given below is customer wise break down of the assumption.",
        Glovis: "• Glovis: 720",
        Hero: "• Hero: 600",
        Honda: "• Honda: 20923",
        Hyundai: "• Hyundai: 0",
        Kia: "• Kia: 10722",
        Mahindra: "• Mahindra: 55943",
        Mobis: "• Mobis: 22443",
        Nippon: "• Nippon: 8700",
        PSA: "• PSA: 1430",
        RE: "• RE: 112450",
        Renault: "• Renault: 7583",
        RNAIPL: "• RNAIPL: 7539",
        TACO: "• TACO: 7900",
        TATA: "• TATA: 0",
        TVS: "• TVS: 0",
        VW: "• VW: 10095",
        Zspares: "• Zspares: 4375",
      },
    ],
    "What are the order assumptions of all parts of Hero?": [
      {
        sentence:
          "The Hero customer has 3 VPNs, VPPWNF-10849-BB, VPPWNF-10849-AD, and VPPWNF-10849-CB. The following is the breakdown.",
        formattedData: `
    1. VPN: VPPWNF-10849-BB
      • Program: ACJB
      • May'24: 0
      • Jun'24: 0
      • July'24: 0
      • Aug'24: 0
      • Sept'24: 0
      • Oct'24: 0 \n
    2. VPN: VPPWNF-10849-AD
      • Program: RAA
      • May'24: 600
      • Jun'24: 2500
      • July'24: 2000
      • Aug'24: 2000
      • Sept'24: 2000
      • Oct'24: 3000 \n
    3. VPN: VPPWNF-10849-CB
      • Program: RAA
      • May'24: 0
      • Jun'24: 0
      • July'24: 500
      • Aug'24: 500
      • Sept'24: 500
      • Oct'24: 500
    `,
          },
        ],
        "What do I need to do today?":[
          {
            sentence:"Based on the data retrieved from Agent-M, here are the key tasks and actions you need to focus on today:",
            formattedData:`
    1.⁠ ⁠Delivery Dates to Approve:
      •⁠  ⁠Steel Invest (PO: p/129462-1): Approve or reject the new delivery date of 11 September 2024 (previously 06 September 2024). This is a 5-day delay.
      •⁠  ⁠Cosasteel (PO: p/923307-1): Approve or reject the new delivery date of 22 August 2024 (previously 20 August 2024). This is a 2-day delay.
    2.⁠ ⁠Documents to Review:
      •⁠  ⁠Nvidia (PO: p/214534-1): Review the newly uploaded Letter of Credit document received on 10 August 2024.
    3.⁠ ⁠Documents to Chase:
      •⁠  ⁠Alcoa Corporation (PO: p/279981-1): Chase the delivery note for 63 units of aluminum plate, 6061-T6, 1/4 inch thickness, 12 x 12 inches.
      •⁠  ⁠Niconductors (PO: p/923307-1): Chase the delivery note for 96 units of GPS module, 72-channel, 1Hz update rate, SMT.
      •⁠  ⁠Saha Steel Industries (PO: p/149628-1): Chase the material certificate for 48 units of carbon steel plate, 1045, 3/4 inch thickness, 60 x 120 inches.
      •⁠  ⁠Steel Invest (PO: p/250534-1): Chase the invoice for 26 units of steel coil, hot rolled, A1011, 0.125 inch thickness, 36 inches width.
    4.⁠ ⁠Orders to Follow Up:
      •⁠  ⁠Steel Invest (PO: p/250534-1): Follow up on the ex-factory date of 15 August 2024 (last update on 12 August 2024).
      •⁠  ⁠Steel Invest (PO: p/267171-1): Follow up on the ex-factory date of 20 August 2024 (last update on 17 August 2024).
      •⁠  ⁠Hanwa (PO: p/514817-1): Follow up on the ex-factory date of 22 August 2024 (last update on 09 August 2024).
    5.⁠ ⁠Proposed Risks to Address:
      •⁠  ⁠Alcoa Corporation (PO: p/171201-1): Address the risk related to the drop in European manufacturing affecting aluminum scrap availability.
      •⁠  ⁠British Steel (PO: p/278297-1): Address the risk of potential job losses due to British Steel's decarbonisation plan.
      •⁠  ⁠Cosasteel (PO: p/205912-1): Address the risk of Thai economy upheaval due to factory closures and cheap Chinese imports.
      •⁠  ⁠Dillinger Group (PO: p/227140-1): Address the risk related to the drop in European manufacturing affecting aluminum scrap availability.
      •⁠  ⁠Hanwa (PO: p/514817-1): Address the risk of Beijing protests over Japan-Taiwan coastguard exercises.
      •⁠  ⁠Hebei Iron and Steel (PO: p/254805-3): Address the risk of Beijing protests over Japan-Taiwan coastguard exercises.
      •⁠  ⁠Hua Hong Semiconductor (PO: p/205912-3): Address the risk of the semiconductor market selloff due to stricter US chip exports to China.
    Summary of Actions:
      •⁠  ⁠Approve/Reject Delivery Dates: 2 tasks
      •⁠  ⁠Review Documents: 1 task
      •⁠  ⁠Chase Documents: 4 tasks
      •⁠  ⁠Follow Up on Orders: 3 tasks
      •⁠  ⁠Address Proposed Risks: 7 tasks
    Please prioritize these tasks based on urgency and impact on your operations. If you need further details or assistance with any specific task, feel free to ask!
            `
      }
    ],
    "Which orders will arrive in next week?":[
      {
        sentence:"Based on the data from Agent-M, here is the order that is expected to arrive next week:",
        formattedData:`
        •⁠  ⁠Purchase Order: P/2799811
        * Item: Aluminum Plate, 6061-T6, 1/4 inch thickness, 12 x 12 inches
        * Quantity: 63 units
        * Supplier: Alcoa Corporation
        * Courier Service Provider: USPS
        * Courier Tracking ID: C/CR85246STU
        * Estimated Arrival Date: 30 August 2024
    This order is scheduled to arrive on 30 August 2024, which falls within the next week. Please ensure that your receiving team is prepared for this delivery. If you need further assistance or additional details, feel free to ask!
            `

      }
    ],
    "Which documents are pending?":[
      {
        sentence:"Based on the data retrieved, here are the documents that are pending and need to be chased:",
        formattedData:`
        •⁠  ⁠Alcoa Corporation:
            * PO Number: P/279981-1
            * Item: Aluminum plate, 6061-T6, 1/4 inch thickness, 12 x 12 inches
            * Order Quantity: 63
            * Pending Document: Delivery Note
        •⁠  ⁠NXP Semiconductors:
            * PO Number: P/923307-1
            * Item: GPS module, 72-channel, 1Hz update rate, SMT
            * *Order Quantity:*96
            * Pending Document: Delivery Note
    These documents are crucial for ensuring the smooth processing and tracking of your orders. I recommend reaching out to the respective suppliers to expedite the submission of these documents.
        `
      }
    ]
  };

  useEffect(() => {
    // console.log(questionData.keys())
    if (data) {
      setTypedData("");
      // Combine sentence and formattedData
      const formattedData = data
        .map((row) => {
          // Ensure that sentence is only added once
          const sentence = row.sentence || "";
          const formattedDataContent =
            row.formattedData ||
            Object.values(row)
              .filter((value) => typeof value === "string")
              .filter((value) => value !== sentence) // Exclude sentence from formattedData
              .join("\n");
          return `${sentence}\n\n${formattedDataContent}`;
        })
        .join("\n\n");

      let index = 0;
      const intervalId = setInterval(() => {
        const nextChar = formattedData[index];
        console.log("Current index:", index, "Character:", nextChar); // Debug log
        if (nextChar === undefined) {
          clearInterval(intervalId);
          return;
        }
        setTypedData((prev) => prev + nextChar);
        index += 1;
      }, 20);
    }
  }, [data]);

  const handleQuestionClick = (question) => {
    setInput("")
    setSelectedQuestion(question);
    setLoadingData(true);
    setShowQuestions(false);
    setTimeout(() => {
      setData(questionData[question] || []);
      setLoadingData(false);
    }, 2000);
  };

  const handleBackClick = () => {
    setShowQuestions(true);
    setSelectedQuestion("");
    setTypedData("");
  };

  return (
    <section className="p-6 pt-12 h-screen w-full flex flex-col items-center dark:bg-slate-950">
      {showQuestions ? (
        <div className="p-3 h-full w-full overflow-auto gap-9 flex flex-col">
          <div className="w-full">
            {/* Question div */}
            <div className="mb-4 dark:text-white space-y-4 flex flex-col items-center">
              <h3 className="text-gray-300 text-2xl font-bold">
                Looking for answers? Ask Agent M
              </h3>
              <img
                src={agent}
                className="w-24 h-24 flex flex-col items-center"
                alt="Agent"
              />
              <h3 className="text-gray-200 text-xl">Purchase Orders</h3>

              <p
                className="bg-blue-500 rounded-2xl p-2 w-[40%] cursor-pointer"
                onClick={() =>
                  handleQuestionClick(
                    "What is the order assumption of part number 3U3F-10B885-AA in June'24?"
                  )
                }
              >
                What is the order assumption of part number 3U3F-10B885-AA in
                June'24?
              </p>
              <p
                className="bg-blue-500 rounded-2xl p-2 w-[40%] cursor-pointer"
                onClick={() =>
                  handleQuestionClick(
                    "What is the assumption of total orders of all parts in May'24?"
                  )
                }
              >
                What is the assumption of total orders of all parts in May'24?
              </p>
              <p
                className="bg-blue-500 rounded-2xl p-2 w-[40%] cursor-pointer"
                onClick={() =>
                  handleQuestionClick(
                    "What do I need to do today?"
                  )
                }
              >
                What do I need to do today?
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-3 h-full w-full overflow-auto scrollbar-none gap-9 flex flex-col">
          <button
            onClick={handleBackClick}
            className="text-white rounded-full px-4 py-2 mb-4 self-start flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
            Back
          </button>

          <div className="flex justify-start gap-2">
            <img src={user} className="w-6 h-6" alt="User" />
            <div className="leading-9">
              <h2 className="font-bold text-black text-xl dark:text-white">
                You{" "}
                <mark className="text-gray-300 bg-inherit text-sm font-medium">
                  Just now
                </mark>
              </h2>
              <div className="dark:text-white">
                <p>{selectedQuestion}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-start gap-2">
            <img src={agent} className="w-6 h-6" alt="Agent M" />
            <div className="leading-9">
              <h2 className="font-bold text-black text-xl dark:text-white">
                Agent-M{" "}
                <mark className="text-gray-300 bg-inherit text-sm font-medium">
                  Just now
                </mark>
              </h2>
              <div className="dark:text-white my-4">
                {loadingData ? (
                  <div className="flex items-center justify-start">
                    <div className="w-12 h-12 border-t-4 border-b-4 border-white rounded-full animate-spin" />
                  </div>
                ) : (
                  <div className="w-full bg-transparent">
                    <div className="text-gray-100 whitespace-pre-wrap leading-8">
                      {typedData}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <SearchSuggestion input={input} setInput={setInput} handleQuestionClick={handleQuestionClick} questions={Object.keys(questionData)}/>

      <div className="relative bottom-0 left-0 bg-slate-950 w-full items-center">
        <input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="w-full py-3 pl-4 pr-14 text-xl border rounded-lg bg-gray-200 text-gray-900 dark:bg-inherit dark:text-white outline-none"
          placeholder="Type your message..."
        />
        <button className="absolute top-3 right-4 w-8 h-8" onClick={()=>handleQuestionClick(input)}>
          <img src={sendBtn} alt="Send" />
        </button>
      </div>
    </section>
  );
};

export default Desktop1;
