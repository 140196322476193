import React, { useState, useEffect, useRef } from 'react';
import { BsQuestionCircleFill } from "react-icons/bs";

const SearchSuggestion = ({ input, handleQuestionClick, questions }) => {
    const ulRef = useRef(null);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [ulHeight, setUlHeight] = useState('auto');

    useEffect(() => {
        // Initialize suggestions from the questions array
        const suggestion = questions.map((question, index) => ({
            id: index, // Use index or a unique identifier
            text: question,
        }));
        
        // Filter suggestions based on input
        const matchingSuggestions = suggestion
            .filter(suggesting =>
                suggesting.text.toLowerCase().startsWith(input.toLowerCase())
            );

        setFilteredSuggestions(matchingSuggestions);

        // Adjust the height of the ul element
        if (ulRef.current) {
            const ulScrollHeight = ulRef.current.scrollHeight;
            setUlHeight(ulScrollHeight > 176 ? '176px' : 'auto');
        }
    }, [input, questions]);

    // Check if the input is non-empty and there are matching suggestions
    const shouldRender = input.trim() !== '' && filteredSuggestions.length > 0;

    const highlightText = (text) => {
        if (!input) return text;

        // Only highlight if the input matches the beginning of the text
        if (text.toLowerCase().startsWith(input.toLowerCase())) {
            const match = text.substring(0, input.length);
            const rest = text.substring(input.length);
            return (
                <>
                    <span className="bg-blue-200 text-blue-600">{match}</span>
                    {rest}
                </>
            );
        }
        
        return text;
    };

    if (!shouldRender) {
        return null; // Don't render the section if no suggestions
    }

    return (
        <section className='w-full mb-2 p-3 border rounded flex flex-col bg-white dark:bg-slate-950'>
            <div className='flex gap-3 items-center mb-3'>
                <BsQuestionCircleFill className='text-blue-400 bg-black rounded-xl' />
                <h3 className='dark:text-white'>ASK A QUESTION</h3>
            </div>
            <ul
                ref={ulRef}
                className='overflow-auto'
                style={{ height: ulHeight }}
            >
                {filteredSuggestions.map(suggesting => (
                    <li
                        key={suggesting.id}
                        className="hover:bg-gray-200 p-1 rounded-md cursor-pointer dark:text-white dark:hover:bg-gray-700"
                        onClick={() => handleQuestionClick(suggesting.text)}
                    >
                        {highlightText(suggesting.text)}
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default SearchSuggestion;